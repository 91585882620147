@media screen and (max-width:1510px) {
	.contact-page {
		height: 100%;
		background-color: white;
		padding-top: 50px;
		padding-bottom: 56px;

		img {
			width: 100%;
			box-shadow: 0 2px 14px rgba(0, 0, 0, 0.159);
			border-radius: 15px;
		}

		.main-contact-section {

			display: flex;
			flex-direction: column;
			justify-content: space-between;



			.blue-contact {
				min-height: 260px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h3 {
					font-weight: 300;
					font-size: 28px;
					margin-bottom: 0;
				}

				h1 {
					font-weight: 300;
					font-size: 40px;
					margin: 0;
				}

				p {
					font-size: 18px;
					margin: 0;
				}

				h3,
				h1,
				p {
					color: black;
				}
			}

			.white-contact {
				padding-bottom: 50px;

				h1 {
					font-size: 28px;
					font-weight: 300;
					color: #206da0;
					font-weight: bold;
				}

				p {
					max-width: 300px;
					font-size: 18px;
					font-weight: 300;
					margin: 0;
				}

				i {
					color: rgb(109, 109, 109);
				}

				.address {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}
			}
		}



	}
}

@media screen and (min-width:1511px) {
	.contact-page {
		height: 100%;
		background: linear-gradient(to bottom, #3498db 0%, #3498db 55%, white 0%, white 45%);
		padding-top: 50px;
		padding-bottom: 56px;

		img {
			width: 100%;
			box-shadow: 0 2px 14px rgba(0, 0, 0, 0.159);
			border-radius: 15px;
		}

		.main-contact-section {

			display: flex;
			flex-direction: column;
			justify-content: space-between;



			.blue-contact {
				min-height: 260px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				h3 {
					font-weight: 300;
					font-size: 28px;
					margin-bottom: 0;
				}

				h1 {
					font-weight: 300;
					font-size: 40px;
					margin: 0;
				}

				p {
					font-size: 18px;
					margin: 0;
				}

				h3,
				h1,
				p {
					color: white;
				}
			}

			.white-contact {
				padding-bottom: 50px;

				h1 {
					font-size: 28px;
					font-weight: 300;
					color: #206da0;
					font-weight: bold;
				}

				p {
					max-width: 300px;
					font-size: 18px;
					font-weight: 300;
					margin: 0;
				}

				i {
					color: rgb(109, 109, 109);
				}

				.address {
					display: flex;
					flex-direction: column;
					justify-content: space-around;
				}
			}
		}



	}
}