.about-page {
    .image-profile {
        img {
            height: 40%;
            width: 100%;
            border-radius: 5px;
            box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.326);
        }
    }

    .page-title{
        font-size: 42px;
    }

    .justice {
        width: 500px;
    }
    .intro-main{
        font-size: 24px;
        text-wrap:pretty;
    }

    .about-list{
        h1{
            font-size: 20px;
        }
        font-size: 16px;
    }
}