.bgHomePage {
    width: 80%;
    height: 80%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 2px 19px rgba(0, 0, 0, 0.411);
    border: 2px solid #3498db;
    border-top: 0px;
}

.outer-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 10px);
    background-image: url('../img/backgroundHome.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the alpha value for the desired darkness */
}

.inner-container {
    position: relative;
    z-index: 1;
    top: calc(50vh - 150px);
    opacity: 0;
    transform: translateY(50px);
    animation: slideIn 1s ease-out forwards;
}

@media screen and (max-width: 662px) {
    .inner-container {
        position: relative;
        z-index: 1;
        top: calc(50vh - 220px);
        opacity: 0;
        transform: translateY(50px);
        animation: slideIn 1s ease-out forwards;
    }
}



.inner-container button {
    width: 200px;
    height: 70px;
    font-size: 20px;
    margin-top: 2rem;
    transform: translateY(50px);
    animation: slideIn 1s ease-out forwards 0.5s;
}

/* Example text styling */
.inner-container p {
    font-size: 42px;
    color: white;
    text-align: center;
    transform: translateY(50px);
    /* Start position off-screen */
    animation: slideIn 1s ease-out forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 500px) {
    .home-header {
        width: 100%;
        height: inherit;
    }
}

@media screen and (max-width: 403px) {
    .inner-container {
        position: relative;
        z-index: 1;
        top: calc(50vh - 150px);
        opacity: 0;
        transform: translateY(50px);
        animation: slideIn 1s ease-out forwards;
    }
    .inner-container p {
        font-size: 24px;
        color: white;
        text-align: center;
        transform: translateY(50px);
        /* Start position off-screen */
        animation: slideIn 1s ease-out forwards;
    }
}


.HomePage {

    h1,
    h2,
    h3,
    h4,
    h5,
    strong {
        text-align: left !important;
    }

    p {
        font-size: 24px;
        line-height: 130%;
    }

    .home-intro {
        word-wrap: break-word;
        word-break: keep-all;
        word-spacing: 3px;
        max-width: 550px;
        text-align: initial;
    }

    p:after {
        content: '';
        display: inline-block;
        width: 100%;
        vertical-align: top;
    }

    img {
        width: 70%;
        height: 100%;
    }

    .justice {
        border-radius: 15px;
        box-shadow: 0 2px 14px rgba(0, 0, 0, 0.288);
        border: 2px solid #3498db;
    }

    li {
        font-size: 18px;
    }

    .home-list {
        display: flex;
        flex-direction: column;
        text-align: left;

    }
}