@import './header.scss';
@import './Homepage.scss';
@import './footer.scss';
@import './contact.scss';
@import './services.scss';
@import './about.scss';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
html,
body {
    text-align: left !important;
    min-height: 100vh;
}


h1,
h2,
h3,
h4,
h5,
strong {
    font-family: 'IBM Plex Sans', sans-serif;
}



.ant-divider {
    border-top-width: 3px;
    margin-top: 0px;
    // box-shadow: 0 0px 2px  rgba(0, 0, 0, 0.616);
}