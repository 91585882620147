.header{
    background-color: #040F0F;
    color: white;
    h1,img{
        cursor: pointer;
    }
    
}


@media screen and (min-width:700px) {
    #header-large{

    }
    #header-small {
        display: none;
    }
}

@media screen and (max-width:699px) {
    #header-large{
        display: none;
    }
}

