.services {
  margin-bottom: 25px;
  h1{
    text-align: left;
  }
  p{
    margin:0;
  }
  .service-title{
    display: flex;
    padding-left: 10px;
    padding-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: "center";
  }
  .contact-button{
    width: 300px;
    height: 80px;
    background-color:  #3498db;
    font-size: 32px;
    stroke: 4px black;
  }
}