.site-footer{
    text-align: left;
    p{
        font-size: 18px ;
    }

    .footer-address{
        width: 70%;
        p{
            font-size: 12px;
        }
    }
    .footer-socials{
        
        width: 70%;
        p{
            font-size: 14px;
        }
    }

    .footer-contact{
        width: 70%;
        p{
            font-size: 14px;
        }
    }
}